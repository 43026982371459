<template>
  <div>
    <component
      :is="computedComponentName"
      v-model="shouldShowModal"
      :close-on-content-click="false"
      :min-width="computedComponentName === 'v-menu' ? 200 : null"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-center justify-between filter-title"
          :class="{ 'font-weight-bold': isFilterApplied }"
          v-on="on"
          v-bind="attrs"
          >
          <div class="d-flex align-center flex-grow-1">
            <v-icon left>
              list
            </v-icon>
            Bio
          </div>
          <v-icon>
            expand_more
          </v-icon>
        </div>
      </template>
      <v-card>
        <v-card-text
          class="pt-4"
          :class="{ 'black--text': isThemeLight }"
        >
          <div class="mb-1 d-flex">
            <div class="d-flex align-center flex-grow-1">
              <v-icon
                :class="{ 'black--text': isThemeLight }"
                class="mr-1"
                left
              >
                volume_down
              </v-icon>

              Bio
            </div>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="isThemeLight ? 'black' : null"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>

              <span>
                Identify influencers by keywords within their bio description.
              </span>
            </v-tooltip>
          </div>

          <v-text-field
            v-model="form.value"
            dense
            outlined
            hide-details
            placeholder="Keywords in Bio"
            @keyup.enter="submitForm"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </component>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators"

// used throughout the code
const filterName = "bio"

const originalForm = () => ({
  value: ""
})

export default {
  name: "FilterBio",

  data: () => ({
    form: originalForm(),

    shouldShowModal: false,
  }),

  computed: {
    // show the filter as a dialog for mobile dialogs
    computedComponentName() {
      return this.isDesktopDevice ? "v-menu" : "v-dialog"
    },

    // Used to bold the filter name text
    isFilterApplied() {
      return Boolean(this.$store.getters["influencerDiscovery/findFiltersByType"](filterName).length)
    }
  },

  validations: {
    form: {
      value: {
        required
      }
    }
  },

  methods: {
    /**
     * Takes an item and generates the text based on kind and weight
     */
    computeFilterText() {
      return `Bio: ${this.form.value}`
    },

    /**
     * Push the data from either of the inputs to the filters array in Vuex Store
     *
     * @param {String} kind | Either "audience" or "influencer"
     */
    async submitForm() {
      await this.$v.$touch()

      if (this.$v.$anyError) return

      this.$store.dispatch("influencerDiscovery/replaceFilter", {
        type: filterName,
        data: {
          color: "pink lighten-5",
          icon: "list",
          iconColor: "pink",
          text: this.computeFilterText(),
          inputs: {
            ...this.form
          }
        }
      })

      this.shouldShowModal = false
    },
  },

  mounted() {
    // subscribe to window event when the chip is closed
    window.addEventListener("removeFilter", (e) => {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== "influencerDiscovery" || e.detail.item.type !== filterName) return

      // now that it is the one we want to see, continue
      this.form.value = ""
    })

    // subscribe to window event when the chip is closed
    window.addEventListener("addFilter", (e) => {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== "influencerDiscovery" || e.detail.item.type !== filterName) return

      // now that it is the one we want to see, continue
      this.form.value = e.detail.item.data.inputs.value
    })
  }
}
</script>
